import { ApplicationController, useHover, useIntersection  } from 'stimulus-use'
import videojs from 'video.js'
import { useMediaBreakPoints } from "controllers/mixins/media_break_points"

export default class extends ApplicationController {
  static targets = [ 'video', 'playButton', 'avatar' ]

  connect() {
    useMediaBreakPoints(this)

    if(this.isTablet) {
      useIntersection(this, { threshold: 1 })
    } else {
      useHover(this, { element: this.videoTarget })
    }

    this.player = videojs(this.videoTarget, {
      controls: false,
      autoplay: false,
      muted: true,
      loop: true,
      preload: 'auto'
    })
  }

  disconnect() {
    this.player.dispose()
  }

  mouseEnter() {
    this.play()
  }

  mouseLeave() {
    this.pause()
  }

  appear() {
    this.play()
  }

  disappear() {
    this.pause()
  }

  play() {
    this.playPromise = this.player.play()
    this.hidePlayButton(true)
    this.fadeOutAvatar(true)
  }

  async pause() {
    if (this.playPromise) {
      await this.awaitPlayPromise()
    }
    this.player.pause()
    this.hidePlayButton(false)
    this.fadeOutAvatar(false)
  }

  async awaitPlayPromise() {
    try {
      await this.playPromise
    } catch (e) {
      /*global Rollbar*/
      if (typeof Rollbar !== 'undefined') Rollbar.error('Error video play', e)
    }
  }

  hidePlayButton(bool) {
    this.playButtonTarget.classList.toggle('is-hidden', bool)
  }

  fadeOutAvatar(bool) {
    if(this.hasAvatarTarget) {
      this.avatarTarget.classList.toggle('related-video__avatar--fadeout', bool)
    }
  }
}
