import { IntersectionController, useIntersection  } from 'stimulus-use'

export default class extends IntersectionController {
  static targets = [ 'video', 'volumeOffTemplate', 'volumeOnTemplate', 'volumeIcon' ]
  static values = {
    controls: Boolean
  }

  initialize() {
    this.options = {
      threshold: 0.9,
    }

    if(typeof this.muted !== 'boolean') {
      this.muted = true
    }
  }

  async connect() {
    const [videoTarget] = this.videoTargets.slice(-1)
    if(videoTarget) {
      const videojs = (await import("video.js")).default
      this.player = videojs(videoTarget, {
        controls: this.controlsValue,
        autoplay: false,
        muted: this.muted,
        loop: true,
        preload: 'auto'
      })

      useIntersection(this, this.options)
    }
  }

  disconnect() {
    if(this.player) {
      this.player.dispose()
    }
  }

  play(e) {
    e.stopImmediatePropagation()
    if(!this.player || e.target.tagName !== 'VIDEO') return

    if(e.type === 'mouseup' && this.controlsValue) {
      if(this.player.paused()) {
        this.player.play();
      } else {
        this.player.pause();
      }
    }

    this.switchVolume(!this.muted)
  }

  appear() {
    if(this.player) {
      this.playPromise = this.player.play()
    }
  }

  async disappear() {
    if(this.player) {
      if (this.playPromise) {
        await this.awaitPlayPromise()
      }
      this.player.pause()
    }
  }

  async awaitPlayPromise() {
    try {
      await this.playPromise
    } catch (e) {
      /*global Rollbar*/
      if (typeof Rollbar !== 'undefined') Rollbar.error('Error video play', e)
    }
  }

  updateVolume() {
    this.player.muted(this.muted)
  }

  switchVolume(muted) {
    this.muted = muted

    if (this.hasVolumeIconTarget) {
      this.element.removeChild(this.volumeIconTarget)
    }

    const controllers = this.application.controllers.filter(c => c.identifier == this.identifier)
    for (const c of controllers) {
      c.updateVolume()
    }

    if(this.hasVolumeOffTemplateTarget) {
      let icon_element_html = this.volumeOnTemplateTarget.innerHTML
      if(muted) {
        icon_element_html = this.volumeOffTemplateTarget.innerHTML
      }
      this.element.insertAdjacentHTML('beforeend', icon_element_html);
    }
  }

  get muted() {
    return window.muted
  }

  set muted(value) {
    window.muted = value
  }
}
