import { ApplicationController } from "stimulus-use";
import { loadStripe } from "@stripe/stripe-js";
import stripeStyle from "lib/stripe-style";

export default class extends ApplicationController {
  static values = {
    publishableKey: String,
    clientSecret: String,
    returnUrl: String,
    stripeBillingAddress: Object,
  };

  static targets = [
    "cardError",
    "cardErrorTemplate",
    // 'token',
    // 'lastDigits',
    // 'month',
    // 'year',
    // 'ccType'
    "elements",
  ];

  connect() {
    // eslint-disable-next-line camelcase
    this.payment_intent = null;

    console.info("Stripe payment intents init");
    // console.log(this.publishableKeyValue);
    // console.log(this.clientSecretValue);

    loadStripe(this.publishableKeyValue).then((stripe) => {
      // console.log("stripe init 2");
      this.stripe = stripe;
      this.elements = this.stripe.elements({
        clientSecret: this.clientSecretValue,
        locale: "en",
        ...stripeStyle,
      });

      const paymentElement = this.elements.create("payment", {
        fields: {
          billingDetails: {
            address: {
              country: "never",
              postalCode: "never",
            },
          },
        },
      });

      paymentElement.mount(this.elementsTarget);
    });

    this.form.addEventListener("submit", this.confirmPayment.bind(this));
  }

  disconnect() {
    this.form.removeEventListener("submit", this.confirmPayment.bind(this));
  }

  async confirmPayment(event) {
    event.preventDefault();
    // console.log('on submit')

    this.clearErrorMessage();

    // prevent double call for confirmPayment
    if (this.payment_intent) {
      window.location.replace(this.returnUrlValue + "?redirect_status=succeeded");
    }

    // console.log('on submit2')

    const { elements, stripe } = this;

    /* eslint-disable camelcase */
    const confirmParams = {
      return_url: this.returnUrlValue,
      payment_method_data: {
        billing_details: {
          address: this.stripeBillingAddressValue,
        },
      },
    };

    const result = await stripe.confirmPayment({ elements, confirmParams });

    console.log(result);

    if (result.error) {
      // console.log("onerrorsubmit");
      this.displayError(result.error.message);
      this.formControllers.forEach(controller => controller.enableForm());

      if (result.error.payment_intent) {
        this.payment_intent = result.error.payment_intent;
        window.location.replace(this.returnUrlValue + "?redirect_status=succeeded");
      } else {
        return false;
      }
    }
    /* eslint-enable camelcase */
  }

  clearErrorMessage() {
    if (this.hasCardErrorTarget) {
      this.cardErrorTarget.remove();
    }
  }

  displayError(message) {
    this.clearErrorMessage();

    this.element.insertAdjacentHTML(
      "afterbegin",
      this.cardErrorTemplateTarget.innerHTML,
    );
    this.cardErrorTarget
      .querySelector(".notification-content__state-text")
      .insertAdjacentHTML("beforeend", message);
  }

  // submitStripeResults(token) {
  //   console.log('assign ...')
  //
  //   this.tokenTarget.value = token.id
  //   this.lastDigitsTarget.value = token.card.last4
  //   this.monthTarget.valu= token.card.exp_month
  //   this.yearTarget.value = token.card.exp_year
  //   this.ccTypeTarget.value = this.ccTypeMap(token.card.brand || token.card.type)
  //
  //   return this.form.submit()
  // }

  // ccTypeMap(source) {
  //   const map = {
  //     mastercard:  'mastercard',
  //     visa: 'visa',
  //     amex: 'amex',
  //     discover: 'discover',
  //     diners: 'dinersclub',
  //     jcb: 'jcb',
  //     unionpay: 'unionpay',
  //     'american express': 'amex',
  //     'diners club': 'dinersclub',
  //   }
  //
  //   return map[source.toLowerCase()]
  // }

  get form() {
    return this.element.closest("form");
  }

  get formControllers() {
    return this.application.controllers.filter(
      (c) => c.identifier === "checkout--form",
    );
  }
}